/* @import "../Font/gilroy/Gilroy-Medium.ttf"; */
*/ .App {
  text-align: center;
}
.login-box {

  min-height: 80vh; 
  } 
/* html {
  height: 100% !important;
}
body {
  min-height: 100% !important;
} */
.txt-grey {
  color: #909090 !important;
}
#standard-basic {
  color: "pink" !important;
}

.link {
  /* text-decoration: none; */
  color: inherit;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  margin: 0 !important;
}
.container{
  max-width: 100% !important;
  margin: 0 !important;
}
.loginCard {
  width: 350px;
  text-align: center;
  border-radius: 1;
  position: absolute;
  top: 6%;
  left: 37%;
}
.loginCardOne {
  width: 391px;
  text-align: center;
  border-radius: 1;
  position: absolute;
  top: 6%;
  left: 37%;
}
.RegisterCard {
  width: 600px !important;
  height: 30% !important;
  text-align: center !important;
  border-radius: 1;
  position: absolute !important;
  top: 7% !important;
  left: 28% !important;
}
.signin_heading {
  text-align: left;
  margin-left: 15%;
  margin-right: 10%;
}
.TandC {
  text-align: left;
  margin-left: 4rem;
}
.TandCprv {
  text-align: left;
  margin-left: 4rem;

  /* margin-bottom: 5%; */
}
.forgotpwd {
  text-align: left;
  margin-left: 55%;
}
.cardsty {
  border-radius: 25px !important;
}
.login-bg {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: grey;
}
.Gender-heading {
  text-align: left !important;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
  /* font-weight: 0;
      font-size: 0.5rem !important;
      line-height: 0 !important;
      letter-spacing: 0.00938em; */
}
.regscreen {
  margin-bottom: "1rem" !important;
}
.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 0.85rem;
}
.fs1rem{
  font-size: 0.95rem;
}

.font{
  font-size: '10px'!important;
}
@font-face {
  font-family: gilroy;
  src: url(./Assets/Font/gilroy/Gilroy-SemiBold.ttf);
}
.test {
  font-family: gilroy;
}
@font-face {
  font-family: gilroyBold;
  src: url(./Assets/Font/gilroy/Gilroy-Bold.ttf);
}
.bold {
  font-family: gilroyBold;
}
@font-face {
  font-family: gilroyMedium;
  src: url(./Assets/Font/gilroy/Gilroy-Medium.ttf);
}
.medium {
  font-family: gilroyMedium;
}
@font-face {
  font-family: gilroyLight;
  src: url(./Assets/Font/gilroy/Gilroy-Light.ttf);
}
.light {
  font-family: gilroyLight;
}
.regscreen {
  margin-top: -3%;
  margin-bottom: 5%;
}
.note{
  text-align: right !important;
 
}
.female-heading {
  font-size: 2px !important;
  font-weight: lighter !important;
}
.small {
  font-size: small !important;
}
.smallradio{
  font-size: 5px !important; 
}
.link2 {
  
  color: inherit !important;
  /* text-transform: none !important; */
  text-decoration: none !important;
  /* background-color: "#379592" */
  /* color: inherit; */
  
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  /* margin-right: 1rem; */
  margin-left: 1.85rem;
  text-align: justify;
  /* border:1px solid black; */
}

.headingColor{
  color:#707070 !important; 
 
}
.red{
  color: red;
  font-size: 20px;
}
.smallRed{
  color: red;
}

.topright {
  position: absolute;
  top: 8px;
  right: 1px;
  /* font-size: 10px; */
  /* border: 1px solid royalblue; */
  padding:0rem;
  z-index: 1;
}
.container {
  position: relative;
}
.login{
  max-width: 350px;
  margin: 0 auto 20px;
  padding: 20px;

/*  margin:' 0 auto 20px';
 border-radius: 4px;
 background-color: #FFF;
 padding: 20px;
 box-sizing: border-box; */
}
.file-style{
  
  display:'none'

}
.bg-color-upload{
background-color: transparent;
  border: none;
  color: #fff;
}
.custom-file-upload{
  border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
::-webkit-file-upload-button {
  background: #379592;
  color: white;
  padding: 0.75rem 1.2rem;
  font-family: 'gilroy';
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.85rem;
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;

}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
 
  border-radius: 20px;
  
 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* background: red;  */
  border-radius: 20px;
  /* background-color: red; */
  /* border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;   */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
  /* background: red;  */
}
/* .swalPadding{
  padding: 2rem !important;
  font-size: 8px !important;
} */

/* .swal2-popup {
  font-size: 0.75rem !important;
  border-radius: 1.2rem !important;
  max-width: 300px !important;
 
} */





.my-swal{

z-index:9999 !important ;

}
.name{
  width:130px;
  /* color: #0079c1; */
  /* height: 2em; 
  line-height: 1em;  */
  /* font-size: 20px;
  font-weight: 400; */
  text-overflow: ellipsis;
  /* margin-bottom: 12px; */
  cursor: pointer;
  word-break: break-all;
  overflow:hidden;
  white-space: nowrap;
}
.name:hover{
  overflow: visible; 
  white-space: normal;
  height:auto;  /* just added this line */
}
.custom-file-input::-webkit-file-upload-button {
   visibility: hidden;
  }
  .custom-file-input::before {
   content: 'Select File';
   display: inline-block;
   background: #f4f4f4;
   border: 1px solid #ccc;
   padding: 6px 12px;
   outline: none;
   white-space: nowrap;
   /* -webkit-user-select: none; */
   cursor: pointer;
   font-weight: 400;
   font-size: 16px;
   line-height: 1.5;
   color: #495057;
   border-radius: 0.25rem;
  }
  .custom-file-input:hover::before {
   border-color: #6c757d;
  }
  .custom-file-input:active::before {
   background-color: #6c757d;
   color: #fff;
  }
.no-border{
  border: none !important;
  /* padding: 1rem !important; */
}
/* #my-element{
  margin-left:1rem
} */


.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #5A6E64;
  color: white;
  text-align: center;
}
.timecss{
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  padding: 0.75rem;
  border-radius: 5px;
  font-size: 14px;
}
