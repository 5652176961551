.Email-in-verifyotp {
  margin-top: 15%;
}

.helper-text {
  margin-left: 20% !important;
}

.btmtext {
  font-size: x-small;
  text-align: left;
}
