.Enteremailtext {
  font-size: x-small;
  margin-right: 15%;
}
.Email-in-frgpwd {
  margin-top: 10%;
}
.frgpswheading{
  text-align: left;
  margin-left: 18% !important;
 
}

