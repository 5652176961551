body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: gilroy;
  src: url(./Assets/Font/gilroy/Gilroy-SemiBold.ttf);
}
.gilroy {
  font-family: gilroy !important;
}

.css-10btvok-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: #379592 !important;
}
.img-height {
  height: 50px;
  width: 50px;
}
.my-swal {
  z-index: 9999 !important ;
}
.cross-img {
  right: -3%;
  left: "1%";
  width: 15px !important;
  position: absolute;
  height: 15px !important;
  cursor: pointer;
  bottom: 100 !important;
}
.notification-img {
  right: -8%;
  left: "3%";
  width: 15px !important;
  position: absolute;
  height: 15px !important;
  cursor: pointer;
  bottom: 100 !important;
}
.camera-ico {
  background-color: #fff;
  position: absolute;
  /* bottom: 10rem; */
  top: 5.2rem;
  border: 1px solid #379592;
  /* right:50rem; */
  left: 4.5rem;
  height: 21px;
  /* padding: 4px; */
  text-align: center;
  width: 25px;
  border-radius: 100px;
  cursor: pointer;
}
.main-div {
  position: relative;
}

.camera-icon {
  background-color: #fff;
  position: absolute;
  bottom: 0.5rem;
  border: 1px solid #379592;
  right: 9.6rem;
  height: 21px;
  /* padding: 4px; */
  text-align: center;
  width: 25px;
  border-radius: 100px;
  cursor: pointer;
}
.camera-icons {
  background-color: #fff;
  position: absolute;
  bottom: 0.5rem;
  border: 1px solid #379592;
  right: 16rem;
  height: 21px;
  /* padding: 4px; */
  text-align: center;
  width: 25px;
  border-radius: 100px;

  cursor: pointer;
}
.cross-pdf {
  /* right: -1%; */
  left: 24%;
  width: 20px !important;
  position: absolute;
  height: 20px !important;
  cursor: pointer;
  /* top:100 !important */
  bottom: 90% !important;
}
.removeIcon {
  left: 19%;
  width: 20px !important;
  position: absolute;
  height: 20px !important;

  bottom: 90% !important;
}
.pdfcss {
  left: 8%;
  width: 15px !important;
  position: absolute;
  height: 15px !important;
  cursor: pointer;
  /* top:100 !important */
  bottom: 70% !important;
}
.cross-combo {
  right: -1%;
  /* left:'1%'; */
  width: 20px !important;
  position: absolute;
  height: 20px !important;
  cursor: pointer;
  /* top:100 !important */
  bottom: 90% !important;
}
/* .video-thumb{
  right: -1%;
 
  width: 20px !important;
  position: absolute;
  height: 20px !important;
  cursor: pointer;
  
  bottom: 90% !important;
} */

.thumbnail-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}

.video-thumb {
  position: absolute; /* Use absolute positioning */
  top: -10px; /* Adjust the top margin to move the cross image closer to the top */
  right: -5px;
  cursor: pointer;
}
.feessedit{
  margin-left: 2rem !important;
}

.thumbnail-span {
  position: relative;
}

.goal-btn {
  border-color: #379592 !important;
}

.cardcamplist{
  height: 3.8rem !important;
}

.Accordion-btn {
  border-color: #fff !important;
  color: #000;
  background-color: #fff !important;
  width: 220px !important;
}

.Accordion-btn1 {
  border-color: #fff !important;
  color: #000;
  background-color: #fff !important;
  width: 220px !important;
}

.goal-title {
  /* width: 10% !important; */
  text-decoration: underline;
}
.Radiobtns {
  margin-left: 2rem !important;
}
.parentradiobtn {
  margin-left: 3.6rem !important;
}
.performanceradio {
  margin-left: 2.2rem !important;
}
.parentsperformanceradio {
  margin-left: 3.6rem !important;
}
.Accordion-btn.MuiOutlinedInput-root:hover fieldset {
  border-color: currentColor; /* Set the desired outline color */
}

.Accordion-btn.MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: currentColor; /* Set the desired outline color */
}

.Accordion-btn.MuiOutlinedInput-root .MuiInputBase-input {
  outline: none;
  box-shadow: none;
}
.main-div {
  position: relative;
}

.camera-icon {
  background-color: #fff;

  position: absolute;

  bottom: 0.8rem;

  border: 1px solid #379592;

  right: 10rem;

  height: 21px;

  /* padding: 4px; */

  text-align: center;

  width: 25px;

  border-radius: 100px;
}
.custom-checkbox .MuiSvgIcon-root {
  fill: black; /* Default color for unchecked state */
}

.custom-checkbox input:checked + .MuiSvgIcon-root {
  fill: #379592; /* Change this to your desired color for checked state */
}
/* .Radio-root {
   Add your default styles here, for example:
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 50%;
} */

/* Styles for radio buttons after selection */
/* .Radio-root:checked {
 
  border-color: #007bff; /* Blue color */
/* background-color: #007bff; /* Blue color */
/* color: #fff; /* Text color when selected */
/* }  */
*/ */ */ .camera-icons {
  background-color: #fff;

  position: absolute;

  bottom: 0.5rem;

  border: 1px solid #379592;

  right: 16rem;

  height: 21px;

  /* padding: 4px; */

  text-align: center;

  width: 25px;

  border-radius: 100px;
}

/* Styles for the content and remove icon */
.content {
  margin-right: 8px; /* Add some spacing between content and icon */
}

.cross-img {
  width: 20px; /* Adjust the size of the remove icon */
  height: 20px;
  cursor: pointer; /* Change cursor to indicate interactivity */
}

/*  MEDIA QUERIES */

.cardMq {
  /* Other properties... */
  max-height: 60px; /* Keep the original maxHeight value */
}
.footerfirst{
  font-size: 0.7rem ;
}




/* Media queries for smaller screens */


@media screen and (max-width: 767px) {

  .cardMq {
    max-height: none; /* Remove the maxHeight restriction for smaller screens */
    height: 80%; /* Set the desired height for smaller screens */
  }
  .rolesname-chipMq {
    /* margin-left: 1rem !important; */
    margin-top: 2rem !important;
  }
  .checkinout-btnMq {
    font-size: 0.8rem !important;
    width: 100px !important;
    margin-bottom: 5% !important;
    /* color: #379592 !important; */
  }
  .goal-btn {
    font-size: 0.7rem !important;
    width: 147px !important;
    margin-top: 5% !important ;
  }
  .exportmedrecd-btnMq {
    font-size: 0.7rem !important;
    width: 149px !important;
    margin-left: 6rem !important;
  }
  .removegoal{
    margin-top: 2rem !important;
    margin-left: 25rem !important;
  }
  .invited-btn{
    font-size: 0.7rem !important;
  }
  .profile-btn{
    font-size: 0.7rem !important;
    margin-top: 1rem !important;
  }
  .adduser-btn{
    margin-top: 1rem !important;
  }
  .searchbyrole{
    margin-top: 1rem !important;
  }
  .viewwelcomepack-btn{
    font-size: 0.4rem !important;
  }
  .createwelcomepack-btn{
    font-size: 0.4rem !important;
  }
  .declineapp-btn{
    font-size: 0.4rem !important;
  }
  .registration-btn{
    font-size: 0.4rem !important;
  }
  .btns{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .taxinvoice{
    margin-top: 2rem !important;
    
    }
    .cardcamplist{
      height: 8rem !important;
    }
    .status-reg{
      margin-top: 1rem !important;
      margin-bottom: 0.5rem !important;
    
    }
    .ndis-card{
      width: 350px !important;
    }
    .createArchived-btn{
      margin-top: 0.1rem !important;
      margin-bottom: 0.5rem !important;
    }
    .feessedit{
      margin-left: 0rem !important;
      margin-top: 1.5rem !important;
    }
    .footerfirst{
      font-size: 0.3rem !important;
    }
    .footersec{
      font-size: 0.3rem !important;
      margin-bottom: 5rem !important;

    }
    .footer{
      height: 1.5rem !important;
    }

    
}

/* Media queries for medium screens */

@media screen and (min-width: 768px) and (max-width: 911px) {
  .cardMq {
    max-height: none;
    height: 90%;
  }
  .rolesname-chipMq {
    /* margin-left: 1rem !important; */
    margin-top: 2rem !important;
  }
  .checkinout-btnMq {
    font-size: 0.8rem !important;
    width: 100px !important;
    margin-bottom: 5% !important;
    /* color: #379592 !important; */
  }
  .goal-btn {
    font-size: 0.7rem !important;
    width: 147px !important;
    margin-top: 5% !important ;
  }
  .exportmedrecd-btnMq {
    font-size: 0.7rem !important;
    width: 149px !important;
    margin-left: 6rem !important;
  }
  .editmedred-btnMq {
    font-size: 0.7rem !important;
    margin-top: 2rem !important;
  }

  .Accordion-btn{
    font-size: 0.5rem;
   width: 190px !important;
  
}

.Accordion-btn1{
  font-size: 0.5rem;
 width: 190px !important;
 margin-right: 2rem !important;

}
.removegoal{
  margin-top: 5rem !important;
  margin-left: 6rem !important;
}

.invited-btn{
  font-size: 0.7rem !important;
}
.profile-btn{
  font-size: 0.7rem !important;
  margin-top: 1rem !important;
}
.adduser-btn{
  margin-top: 1rem !important;
}
.searchbyrole{
  margin-top: 1rem !important;
}
.viewwelcomepack-btn{
  font-size: 0.5rem !important;
}
.createwelcomepack-btn{
  font-size: 0.5rem !important;
}
.declineapp-btn{
  font-size: 0.5rem !important;
}
.registration-btn{
  font-size: 0.5rem !important;
}
.btns{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.taxinvoice{
margin-top: 2rem !important;

}
.cardcamplist{
  height: 6rem !important;
}
.status-reg{
  margin-top: 1rem !important;

}
.ndis-card{
  width: 350px !important;
}
.sendMailToStaff,.gallery-btn,  .participants-btn,.notifications-btn,.checkin-btn{
  font-size: 0.3rem !important;
 width: 50px !important;
 height: 2rem !important;
}
.editcamp{
  font-size: 0.3rem !important;
}
.campnameedit{
  margin-top: 2rem !important;
}

.feessedit{
  margin-left: 0rem !important;
  margin-top: 1.5rem !important;
}
.removeIcon{
  margin-left: 40px !important;
}
.createArchived-btn{
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;
}
.footer{
  height: 2rem !important;
}



}

/* Media queries for large screens */

@media screen and (min-width: 912px) and (max-width: 1023px) {
  .cardMq {
    max-height: none;
    height: 90%;
  }

  .card-reports{
    /* width: 90% !important; */
   height: 90% !important;
  }

  .adduser-btn{
    margin-top: 1rem !important;
  }

  .invited-btn{
    font-size: 0.7rem !important;
  }
  .profile-btn{
    font-size: 0.7rem !important;
    margin-top: 1rem !important;
  }

  .rolesname-chipMq {
    /* margin-left: 1rem !important; */
    font-size: 0.5rem !important;
  }
  .text-applireviewMedMq {
    margin-left: 18% !important;
  }
  .exportmedrecd-btnMq {
    font-size: 0.6rem !important;
    width: 132px !important;
  }
  .width-text {
    width: 90% !important;
  }
  .text-listitemMq {
    margin-left: 2% !important;
    width: 90% !important;
  }
  .goal-btn {
    font-size: 0.5rem !important;
    width: 117px !important;
    margin-top: 5% !important ;
  
  }

  .checkinout-btnMq {
    font-size: 0.8rem !important;
    width: 100px !important;
    margin-bottom: 5% !important;
    /* color: #379592 !important; */
  }
  .text-age{
    width: 70% !important;
  }
  .Accordion-btn{
  font-size: 0.5rem;
 width: 190px !important;
  }

  .Accordion-btn1{
    font-size: 0.5rem;
   width: 190px !important;
    }

    .Allergyinfo{
      margin-top: 4rem !important;
    }
    .cardcamplist{
      height: 6rem !important;
    }
    .participantscamplist{
      height: 7rem !important; 
    }
    .status-reg{
      margin-top: 1rem !important;
   
    }
    .viewwelcomepack-btn{
      font-size: 0.7rem !important;
    }
    .createwelcomepack-btn{
      font-size: 0.7rem !important;
    }
    .declineapp-btn{
      font-size: 0.7rem !important;
    }
    .registration-btn{
      font-size: 0.7rem !important;
    }
    .btns{
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .agess{
      width: 140px !important;
    }

    .namess{
      width: 140px !important;   
    }
    .customername{
      font-size: 0.8rem !important;
    }
    .customernamefield{
   width: 170px !important;
    }
    .taxinvoice{
      margin-right: 2rem !important;
      font-size: 1.20rem !important;
    }
    .ndis-card{
      width: 350px !important;
    }
    .sendMailToStaff,.gallery-btn,  .participants-btn,.notifications-btn,.checkin-btn{
      font-size: 0.5rem !important;
     width: 90px !important;
     height: 2rem !important;
    }
    .editcamp{
      font-size: 0.9rem !important;
    }
    .removeIcon{
      margin-left: 2rem !important;
    }
    .createArchived-btn{
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .footer{
      height: 2rem !important;
    }

}

@media screen and (max-width: 1024px) {
  .exportmedrecd-btnMq {
    font-size: 0.7rem !important;
    width: 150px !important;
  }

  .goal-btn {
    font-size: 0.7rem !important;
    width: 147px !important;
    margin-top: 5% !important ;
  }

  .checkinout-btnMq {
    font-size: 0.8rem !important;
    width: 100px !important;
    margin-bottom: 5% !important;
    /* color: #379592 !important; */
  }

  .profile-btn{
    margin-top: 1rem !important;
  }
  .sendMailToStaff,.gallery-btn,  .participants-btn,.notifications-btn,.checkin-btn{
    font-size: 0.5rem !important;
   width: 90px !important;
   height: 2rem !important;
  }
  .editcamp{
    font-size: 1rem !important;
  }
  .removeIcon{
    margin-left: 2.9rem !important;
  }
  .createArchived-btn{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
